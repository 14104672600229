import { useCallback, useEffect, useState } from "react";
import { SiweMessage } from "siwe";
import { useAuth } from "@haven1/react-sdk/api";
import { haven1Devnet, haven1Testnet } from "@haven1/wagmi-sdk/chain";
import { useWeb3React } from "@web3-react/core";
import useSignOut from "./useSignOut";

export default function useSignIn() {
  const { nonce: reqNonce, verify, validate } = useAuth();
  const { signOut } = useSignOut();
  const { account, provider } = useWeb3React();
  const isDev = process.env.NODE_ENV === 'development';
  const chain = isDev ? haven1Devnet : haven1Testnet;

  const [isLoading, setIsLoading] = useState(false);
  const [signInStatus, setSignInStatus] = useState<{
    status: boolean;
    address: `0x${string}` | undefined;
  }>({
    status: false,
    address: undefined,
  });

  const signIn = useCallback(async () => {
    if (!account || !provider) return;

    setIsLoading(true);
    try {
      const _nonce = await reqNonce.mutateAsync();
      const nonce = _nonce?.data?.data.nonce;
      if (!nonce) throw new Error("Cannot request nonce");

      const messageSiwe = new SiweMessage({
        domain: window.location.host,
        address: account,
        statement: "Sign in with Haven1 to the app.",
        uri: window.location.origin,
        version: "1",
        chainId: chain.id,
        expirationTime: new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        ).toISOString(),
        nonce,
      })

      const message = messageSiwe.prepareMessage();
      const signature = await provider.getSigner().signMessage(message);
      const status = await verify.mutateAsync({ message, signature });

      if (status?.data?.status === "success") {
        const signInData = (await validate.refetch()).data?.data?.data;
        if (signInData) {
          setSignInStatus({
            status: signInData?.isSignedIn,
            address: signInData.isSignedIn ? (signInData.address as `0x${string}`) : undefined,
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [account, reqNonce, validate, verify])

  useEffect(() => {
    const signInData = validate.data?.data?.data;
    if (
      signInData?.isSignedIn &&
      signInData.address.toLowerCase() !== account?.toLowerCase()
    ) {
      signOut();
      setSignInStatus({
        status: false,
        address: undefined,
      });
    } else if (signInData) {
      setSignInStatus({
        status: signInData?.isSignedIn,
        address: signInData.isSignedIn ? (signInData.address as `0x${string}`) : undefined,
      });
    }
  }, [account, validate.data?.data?.data])

  return {
    isLoading,
    signIn,
    signInStatus,
  }
}