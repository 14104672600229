import { ChainId } from '@uniswap/sdk-core'
import { Connector } from '@web3-react/types'
import {
  deprecatedNetworkConnection,
  networkConnection,
  uniwalletWCV2ConnectConnection,
  walletConnectV2Connection,
  useProvider
} from 'connection'
import { getChainInfo } from 'constants/chainInfo'
import { CHAIN_IDS_TO_NAMES, SupportedInterfaceChain, isSupportedChain } from 'constants/chains'
import { FALLBACK_URLS, RPC_URLS } from 'constants/networks'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'state/hooks'
import { endSwitchingChain, startSwitchingChain } from 'state/wallets/reducer'
import { ethers } from 'ethers';

function getRpcUrl(chainId: SupportedInterfaceChain): string {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.GOERLI:
    case ChainId.SEPOLIA:
      return RPC_URLS[chainId][0]
    // Attempting to add a chain using an infura URL will not work, as the URL will be unreachable from the MetaMask background page.
    // MetaMask allows switching to any publicly reachable URL, but for novel chains, it will display a warning if it is not on the "Safe" list.
    // See the definition of FALLBACK_URLS for more details.
    default:
      return FALLBACK_URLS[chainId][0]
  }
}

export function useMetamaskChainId() {
  const provider = (window.ethereum as any)
  const [chainId, setChainId] = useState<number | null>(null);

  const fetchChainId = useCallback(async () => {
    if (provider) {
      try {
        setChainId(Number.parseInt(provider.chainId, 16));
      } catch (error) {
        console.error("Error fetching chainId:", error);
        setChainId(null);
      }
    } else {
      setChainId(null);
    }
  }, [provider?.chainId]);

  useEffect(() => {
    fetchChainId();

    const handleChainChanged = (chainIdHex: string) => {
      const newChainId = parseInt(chainIdHex, 16);
      setChainId(newChainId);
    };

    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length === 0) {
        setChainId(null);
      } else {
        fetchChainId(); // Re-fetch chainId when accounts change
      }
    };

    if(provider){
      // Listen for MetaMask events
      provider.on('chainChanged', handleChainChanged);
      provider.on('accountsChanged', handleAccountsChanged);

      // Clean up listeners on unmount
      return () => {
        provider.removeListener('chainChanged', handleChainChanged);
        provider.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
    return 
  }, [fetchChainId]);

  return chainId;
}

export function useSwitchChain() {
  const dispatch = useAppDispatch()
  const provider = (window.ethereum as any)//useProvider();

  return useCallback(
    async (connector: Connector, chainId: ChainId) => {
      if (!isSupportedChain(chainId)) {
        throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
      } else {
        dispatch(startSwitchingChain(chainId))
        try {
          if (
            [
              walletConnectV2Connection.connector,
              uniwalletWCV2ConnectConnection.connector,
              networkConnection.connector,
              deprecatedNetworkConnection.connector,
            ].includes(connector)
          ) {
            await connector.activate(chainId)
          } else {
            const info = getChainInfo(chainId)
            if(provider){
              const chainIdHex = '0x' + Number(chainId).toString(16);
              provider.request({
                "method": "wallet_addEthereumChain",
                "params": [
                  {
                    "chainId": chainIdHex,
                    "chainName": info.label,
                    "rpcUrls": [getRpcUrl(chainId)],
                    "iconUrls": [],
                    "nativeCurrency": {
                      "name": info.nativeCurrency.name,
                      "symbol": info.nativeCurrency.symbol,
                      "decimals": info.nativeCurrency.decimals
                    },
                    "blockExplorerUrls": [info.explorer]
                  }
                ]
              });
            } else {
              const addChainParameter = {
                chainId,
                chainName: info.label,
                rpcUrls: [getRpcUrl(chainId)],
                nativeCurrency: info.nativeCurrency,
                blockExplorerUrls: [info.explorer],
              }
              await connector.activate(addChainParameter)
            }
          }
        } catch (error) {
          // In activating a new chain, the connector passes through a deactivated state.
          // If we fail to switch chains, it may remain in this state, and no longer be usable.
          // We defensively re-activate the connector to ensure the user does not notice any change.
          try {
            await connector.activate()
          } catch (error) {
            console.error('Failed to re-activate connector', error)
          }
          throw error
        } finally {
          dispatch(endSwitchingChain())
        }
      }
    },
    [dispatch]
  )
}
