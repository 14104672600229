import { BigNumber } from '@ethersproject/bignumber'
import { t } from '@lingui/macro'
import { CustomUserProperties, SwapEventName } from '@uniswap/analytics-events'
import { Currency, Percent, Ether } from '@uniswap/sdk-core'
import { FlatFeeOptions, SwapRouter, UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { SwapType } from 'state/routing/types'
import { FeeOptions, toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, useTrace } from 'analytics'
import { useCachedPortfolioBalancesQuery } from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper'
import { getConnection } from 'connection'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { formatCommonPropertiesForTrade, formatSwapSignedAnalyticsEventProperties } from 'lib/utils/analytics'
import { useCallback } from 'react'
import { ClassicTrade, TradeFillType } from 'state/routing/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { trace } from 'tracing/trace'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import isZero from 'utils/isZero'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'
import { getWalletMeta } from 'utils/walletMeta'
import { getAddress } from '@ethersproject/address'

import { PermitSignature } from './usePermitAllowance'
import { getH1FeeForContract, H1_FEE_FUNCTION_SIGNATURE } from '@haven1network/h1-fee'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { getNoFeePairs } from '../config/noFeePairs'

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}

export class InsufficientFeeError extends Error {
  constructor() {
    super(t`Insufficient H1 for application fee.`)
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  deadline?: BigNumber
  permit?: PermitSignature
  feeOptions?: FeeOptions
  flatFeeOptions?: FlatFeeOptions
}

export const DEFAULT_FEE_BIPS = 20
export const FEE_RECIPIENT = process.env.REACT_APP_ASSOCIATION_ADDRESS!

const NO_FEE_PAIRS = getNoFeePairs(process.env.REACT_APP_CHAIN_ID)

export function shouldApplyFee(tokenIn: string, tokenOut: string): boolean {
  try {
    const normalizedPairKey = [
      getAddress(tokenIn),
      getAddress(tokenOut)
    ].sort().join('-')
    
    return !NO_FEE_PAIRS.has(normalizedPairKey)
  } catch (e) {
    console.warn('Invalid address format:', e)
    return true // Apply fee by default if addresses are invalid
  }
}

// Create a custom Haven1 native currency class
class Haven1 extends Ether {
  protected constructor(chainId: number) {
    super(chainId)
    Object.defineProperty(this, 'name', { value: 'Haven1' })
    Object.defineProperty(this, 'symbol', { value: 'H1' })
  }

  public static onChain(chainId: number): Haven1 {
    return new Haven1(chainId)
  }
}

export function useUniversalRouterSwapCallback(
  trade: ClassicTrade | undefined,
  fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number },
  options: SwapOptions,
  currency?: Currency
) {
  const { account, chainId, provider, connector } = useWeb3React()
  const analyticsContext = useTrace()
  const blockNumber = useBlockNumber()
  const isAutoSlippage = useUserSlippageTolerance()[0] === 'auto'
  const { data } = useCachedPortfolioBalancesQuery({ account })
  const portfolioBalanceUsd = data?.portfolios?.[0]?.tokensTotalDenominatedValue?.value
  
  // Create native currency instance using Haven1 class
  const nativeCurrency = chainId ? Haven1.onChain(chainId) : undefined

  const nativeBalance = useCurrencyBalance(
    account ?? undefined,
    nativeCurrency
  )

  return useCallback(async () => {
    return trace('swap.send', async ({ setTraceData, setTraceStatus, setTraceError }) => {
      try {
        if (!account) throw new Error('missing account')
        if (!chainId) throw new Error('missing chainId')
        if (!provider) throw new Error('missing provider')
        if (!trade) throw new Error('missing trade')
        const connectedChainId = await provider.getSigner().getChainId()
        if (chainId !== connectedChainId) throw new WrongChainError()

        setTraceData('slippageTolerance', options.slippageTolerance.toFixed(2))

        const tokenIn = trade.inputAmount.currency.wrapped.address;
        const tokenOut = trade.outputAmount.currency.wrapped.address;
        
        let feeOptions;
        if (shouldApplyFee(tokenIn, tokenOut)) {
          feeOptions = {
            fee: new Percent(DEFAULT_FEE_BIPS, 10000),
            recipient: FEE_RECIPIENT
          };
        }

        const { calldata: data, value } = SwapRouter.swapERC20CallParameters(trade, {
          slippageTolerance: options.slippageTolerance,
          deadlineOrPreviousBlockhash: options.deadline?.toString(),
          inputTokenPermit: options.permit,
          fee: feeOptions,
          flatFee: options.flatFeeOptions,
        })

        const h1fee = await getH1FeeForContract(
          chainId,
          UNIVERSAL_ROUTER_ADDRESS(chainId),
          H1_FEE_FUNCTION_SIGNATURE(chainId).UniversalRouter.swap,
          account,
          value
        )

        const feeFormatted = h1fee ? parseInt(h1fee.toString()) : 0
        const nativeBalanceFormatted = nativeBalance ? parseInt(`0x${nativeBalance.quotient.toString(16)}`, 16) : 0
        
        if (nativeBalanceFormatted < feeFormatted) {
          throw new InsufficientFeeError()
        }

        const tx = {
          from: account,
          to: UNIVERSAL_ROUTER_ADDRESS(chainId),
          data,
          // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
          ...(h1fee && !isZero(h1fee.toString()) ? { value: toHex(h1fee.toString()) } : {}),
        }

        let gasEstimate: BigNumber
        try {
          gasEstimate = await provider.estimateGas(tx)
        } catch (gasError) {
          setTraceStatus('failed_precondition')
          setTraceError(gasError)
          sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
            ...formatCommonPropertiesForTrade(trade, options.slippageTolerance),
            ...analyticsContext,
            client_block_number: blockNumber,
            tx,
            isAutoSlippage,
          })
          console.warn(gasError)
          throw new GasEstimationError()
        }
        const gasLimit = calculateGasMargin(gasEstimate)
        setTraceData('gasLimit', gasLimit.toNumber())
        const beforeSign = Date.now()
        const response = await provider
          .getSigner()
          .sendTransaction({ ...tx, gasLimit })
          .then((response) => {
            sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
              ...formatSwapSignedAnalyticsEventProperties({
                trade,
                timeToSignSinceRequestMs: Date.now() - beforeSign,
                allowedSlippage: options.slippageTolerance,
                fiatValues,
                txHash: response.hash,
                portfolioBalanceUsd,
              }),
              ...analyticsContext,
              // TODO (WEB-2993): remove these after debugging missing user properties.
              [CustomUserProperties.WALLET_ADDRESS]: account,
              [CustomUserProperties.WALLET_TYPE]: getConnection(connector).getProviderInfo().name,
              [CustomUserProperties.PEER_WALLET_AGENT]: provider ? getWalletMeta(provider)?.agent : undefined,
            })
            if (tx.data !== response.data) {
              sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
                txHash: response.hash,
                ...analyticsContext,
              })

              if (!response.data || response.data.length === 0 || response.data === '0x') {
                throw new ModifiedSwapError()
              }
            }
            return response
          })
        return {
          type: TradeFillType.Classic as const,
          response,
        }
      } catch (swapError: unknown) {
        if (swapError instanceof ModifiedSwapError) throw swapError

        // GasEstimationErrors are already traced when they are thrown.
        if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

        // Cancellations are not failures, and must be accounted for as 'cancelled'.
        if (didUserReject(swapError)) {
          setTraceStatus('cancelled')
          // This error type allows us to distinguish between user rejections and other errors later too.
          throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
        }

        throw new Error(swapErrorToUserReadableMessage(swapError))
      }
    })
  }, [
    account,
    chainId,
    provider,
    trade,
    nativeBalance,
    options.slippageTolerance,
    options.deadline,
    options.permit,
    options.feeOptions,
    options.flatFeeOptions,
    analyticsContext,
    blockNumber,
    isAutoSlippage,
    fiatValues,
    portfolioBalanceUsd,
    connector,
  ])
}
