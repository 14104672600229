import { useAuth } from "@haven1/react-sdk/api";
import { useCallback, useState } from "react";

export default function useSignOut() {
  const { signOut: signOutMutation, validate } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const signOut = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
      await signOutMutation.mutateAsync();
      await validate.refetch();
      setIsLoading(false);
    }
  }, [isLoading, signOutMutation, validate])

  return {
    isLoading,
    signOut
  };
}