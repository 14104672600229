import '@reach/dialog/styles.css'
import 'connection/eagerlyConnect'
import 'inter-ui'
import 'polyfills'
import 'tracing'

import { ApolloProvider } from '@apollo/client'
import { FeatureFlagsProvider } from 'featureFlags'
import { apolloClient } from 'graphql/data/apollo'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { MulticallUpdater } from 'lib/state/multicall'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async/lib/index'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom'
import { SystemThemeUpdater, ThemeColorMetaUpdater } from 'theme/components/ThemeToggle'
import { TamaguiProvider } from 'theme/tamaguiProvider'
import { isBrowserRouterEnabled } from 'utils/env'
import { getCanonicalUrl } from 'utils/urlRoutes'
import { UnitagUpdaterContextProvider } from 'wallet/src/features/unitags/context'
import Web3Provider from './components/Web3Provider'
import { LanguageProvider } from './i18n'
import App from './pages/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import LogsUpdater from './state/logs/updater'
import OrderUpdater from './state/signatures/updater'
import TransactionUpdater from './state/transactions/updater'
import { ThemeProvider, ThemedGlobalStyle } from './theme'
import RadialGradientByChainUpdater from './theme/components/RadialGradientByChainUpdater'
import { Haven1SDKProvider } from '@haven1/react-sdk/api'
import './globals.css'

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  const location = useLocation()

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <RadialGradientByChainUpdater />
      <ListsUpdater />
      <SystemThemeUpdater />
      <ThemeColorMetaUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <OrderUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  )
}

const queryClient = new QueryClient()

const container = document.getElementById('root') as HTMLElement

const Router = isBrowserRouterEnabled() ? BrowserRouter : HashRouter

createRoot(container).render(
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <FeatureFlagsProvider>
          <QueryClientProvider client={queryClient}>
            <Haven1SDKProvider
              config={{
                sdkApiUrl: process.env.REACT_APP_SDK_API_URL || "",
                authApiUrl: process.env.REACT_APP_AUTH_API_URL || "",
              }}
            >
              <Router>
                <LanguageProvider>
                  <Web3Provider>
                    <ApolloProvider client={apolloClient}>
                      <BlockNumberProvider>
                        <UnitagUpdaterContextProvider>
                          <Updaters />
                          <ThemeProvider>
                            <TamaguiProvider>
                              <ThemedGlobalStyle />
                              <App />
                            </TamaguiProvider>
                          </ThemeProvider>
                        </UnitagUpdaterContextProvider>
                      </BlockNumberProvider>
                    </ApolloProvider>
                  </Web3Provider>
                </LanguageProvider>
              </Router>
            </Haven1SDKProvider>
          </QueryClientProvider>
        </FeatureFlagsProvider>
      </Provider>
    </HelmetProvider>
  </StrictMode>
)

serviceWorkerRegistration.unregister();
