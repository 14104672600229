interface Token {
  name: string
  address: string
}

interface TokenPair {
  token1: Token
  token2: Token
}

// Testnet pairs (chainId: 810)
const TESTNET_NO_FEE_PAIRS: TokenPair[] = [
  {
    token1: { name: "hUSDT", address: "0x3F4b531b19f176Ef05258c33974aCF1Bc2C708E4" },
    token2: { name: "hUSDC", address: "0x73dBaF6987B8B37cF201d20Db86AD48B89641017" }
  },
  {
    token1: { name: "hsETH", address: "0x808422B173E408D992d30071e80253d047BfEc63" },
    token2: { name: "hETH", address: "0xd6e17Ea2F27E1ad83fD41AC5D1A3cb96CA5FC0Ac" }
  },
  {
    token1: { name: "hsETH", address: "0x808422B173E408D992d30071e80253d047BfEc63" },
    token2: { name: "hcbETH", address: "0x5F5D88D6cD5A772E82403b346754B7a0Cf535576" }
  },
  {
    token1: { name: "hcbETH", address: "0x5F5D88D6cD5A772E82403b346754B7a0Cf535576" },
    token2: { name: "hETH", address: "0xd6e17Ea2F27E1ad83fD41AC5D1A3cb96CA5FC0Ac" }
  }
]

// Staging pairs (chainId: 8110)
const STAGING_NO_FEE_PAIRS: TokenPair[] = [
  {
    token1: { name: "hUSDT", address: "0x3F4b531b19f176Ef05258c33974aCF1Bc2C708E4" },
    token2: { name: "hUSDC", address: "0x73dBaF6987B8B37cF201d20Db86AD48B89641017" }
  }
]

export function getNoFeePairs(chainId: string | undefined): Set<string> {
  const pairs = chainId === '810' ? TESTNET_NO_FEE_PAIRS : 
                chainId === '8110' ? STAGING_NO_FEE_PAIRS : 
                []

  const pairSet = new Set(
    pairs.map(pair => {
      const addresses = [pair.token1.address, pair.token2.address].sort()
      return addresses.join('-')
    })
  )

  return pairSet
} 