import { useState, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components'
import { useIsDarkMode } from '../../../theme/components/ThemeToggle'

const Div1 = styled.div`
    display: flex;
    transform: scale(1);
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    border: 2px solid rgb(255, 255, 255);
    background: radial-gradient(26.73% 140.34% at 0% 0%, rgba(97, 145, 255, 0.20), rgb(255, 255, 255));
    padding: 16px;
    opacity: 1;
    box-shadow: 0px 4px 20px 0px rgba(76, 109, 173, 0.10);  
    margin-bottom: 24px;
    @media only screen and (max-width: 380px) {
        gap: 4px;
    }
`

const Div2 = styled.div`
    display: flex; flex-shrink: 0; align-items: flex-start;
`

const Div3 = styled.div`
    border-radius: 50%; background-color: rgb(255, 255, 255); padding: 8px 10px 6px 10px;
    @media only screen and (max-width: 380px) {
        display: none;
    }
`

const Div4 = styled.div`
    display: flex; flex-direction: column; flex: 1; gap: 12px;
`

const Div5 = styled.div`
    display: flex; flex-direction: column; gap: 4px;
`

const Div6 = styled.div`
    display: flex; gap: 4px;
`

const Div7 = styled.div`
    display: flex; flex-wrap: nowrap; gap: 4px;
`

const Div8 = styled.div`
    display: flex; flex-wrap: nowrap; align-items: center; gap: 4px;
    color: #ffffff;
`

const Div9 = styled.div`
    display: flex; flex-shrink: 0; cursor: pointer; align-items: flex-start; padding: 4px; color: rgb(57, 58, 64);
`

const H4 = styled.h4`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
`

const P = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin:0px;
`

const A = styled.a`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    text-decoration: none;
    align-self: center;
    margin: 0px 16px;
`

const Button1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 9999px;
    background-color: rgb(31, 99, 255);
    padding: 8px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    box-shadow: 0px 4px 20px 0px rgba(76, 109, 173, 0.10);
`

export function CustomBanner() {
    const [isBannerClosed, setIsBannerClosed] = useState(localStorage.getItem('isBannerClosed') === 'true');

    const handleCloseBanner = useCallback(() => {
        setIsBannerClosed(true);
        localStorage.setItem('isBannerClosed', 'true');
    }, []);

    const isDark = useIsDarkMode()

    if(isBannerClosed){
        return null;
    }

    return (
        <Div1 style={isDark ? {background: "radial-gradient(0% 0% at 26.73% 140.34%, rgb(0, 0, 0), rgba(97, 145, 255, 0.20))"} : {}}>
            <Div2>
                <Div3>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="swap">
                    <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M14.7071 10.7071C14.3166 11.0976 13.6834 11.0976 13.2929 10.7071C12.9024 10.3166 12.9024 9.68342 13.2929 9.29289L14.5858 8L3 8C2.44772 8 2 7.55229 2 7C2 6.44772 2.44772 6 3 6L14.5858 6L13.2929 4.70711C12.9024 4.31658 12.9024 3.68342 13.2929 3.29289C13.6834 2.90237 14.3166 2.90237 14.7071 3.29289L17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7C18 7.26522 17.8946 7.51957 17.7071 7.70711L14.7071 10.7071ZM9.29289 20.7071C9.68342 21.0976 10.3166 21.0976 10.7071 20.7071C11.0976 20.3166 11.0976 19.6834 10.7071 19.2929L9.41421 18L21 18C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16L9.41421 16L10.7071 14.7071C11.0976 14.3166 11.0976 13.6834 10.7071 13.2929C10.3166 12.9024 9.68342 12.9024 9.29289 13.2929L6.29289 16.2929C6.10536 16.4804 6 16.7348 6 17C6 17.2652 6.10536 17.5196 6.29289 17.7071L9.29289 20.7071Z" fill="#1F63FF"/>
                    </g>
                    </svg>

                </Div3>
            </Div2>
            <Div4>
                <Div5>
                    <H4>
                        Introducing Haven Swap V2
                    </H4>
                    <P>
                    Haven Swap V2 is the new, more efficient DEX on Haven1 for traders and LPs. If you’re an LP on V1 Swap, migrate now before V1 is deprecated.
                    </P>
                </Div5>
                <Div6>
                    <a href="https://testnet-swap.haven1.org/" target="_blank">
                        <Button1>
                            <Div7>
                                <Div8>Migrate now</Div8>
                            </Div7>
                        </Button1>
                    </a>
                    <A href="https://docs.haven1.org/haven1-testnet-guide/haven-swap-v2" target="_blank">
                        Learn more
                    </A>
                </Div6>
            </Div4>
            <Div9 onClick={handleCloseBanner}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.52851 3.52876C3.78886 3.26841 4.21097 3.26841 4.47132 3.52876L7.99992 7.05735L11.5285 3.52876C11.7889 3.26841 12.211 3.26841 12.4713 3.52876C12.7317 3.78911 12.7317 4.21122 12.4713 4.47157L8.94273 8.00016L12.4713 11.5288C12.7317 11.7891 12.7317 12.2112 12.4713 12.4716C12.211 12.7319 11.7889 12.7319 11.5285 12.4716L7.99992 8.94297L4.47132 12.4716C4.21097 12.7319 3.78886 12.7319 3.52851 12.4716C3.26816 12.2112 3.26816 11.7891 3.52851 11.5288L7.05711 8.00016L3.52851 4.47157C3.26816 4.21122 3.26816 3.78911 3.52851 3.52876Z" fill="#6B6D76"/>
                </svg>
            </Div9>
        </Div1>
    )
}
