import { Currency, CurrencyAmount, Price, Token, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { INTERNAL_ROUTER_PREFERENCE_PRICE, TradeState } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'

// Create USDC token instance based on environment with fallbacks
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ?? '810'
const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS

// Only create USDC token if we have valid address
const USDC = USDC_ADDRESS ? new Token(
  parseInt(CHAIN_ID),
  USDC_ADDRESS,
  6,
  'hUSDC',
  'Haven USDC'
) : undefined

// Use a larger amount for better price accuracy (1 million USDC)
const USDC_AMOUNT = USDC ? CurrencyAmount.fromRawAmount(USDC, 1_000_000_000_000) : undefined // 1M USDC

/**
 * Returns the USD value (using USDC as proxy) for a given token by simulating a trade
 * @param currency The currency to get the USD price for
 * @returns USD price as number or undefined if not available
 */
export function useTokenToUSDPrice(currency?: Currency): {
  data?: number
  isLoading: boolean
} {
  // Return early if USDC token is not configured
  if (!USDC || !USDC_AMOUNT) {
    return { data: undefined, isLoading: false }
  }

  // Skip if currency is USDC or undefined
  const shouldSkip = !currency || currency.equals(USDC)

  const { trade, state } = useRoutingAPITrade(
    shouldSkip,
    TradeType.EXACT_INPUT,
    USDC_AMOUNT,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE
  )

  return useMemo(() => {
    // If currency is USDC, price is 1
    if (currency?.equals(USDC)) {
      return { data: 1, isLoading: false }
    }

    // Return undefined while loading
    if (state === TradeState.LOADING) {
      return { data: undefined, isLoading: true }
    }

    // If we have a valid trade, calculate the price
    if (trade && trade.outputAmount && trade.inputAmount) {
      // Calculate price: outputAmount (token) per 1 USDC
      const inputUSDC = parseFloat(trade.inputAmount.toExact())
      const outputToken = parseFloat(trade.outputAmount.toExact())
      const pricePerToken = inputUSDC / outputToken // USDC per token
      return { data: pricePerToken, isLoading: false }
    }

    return { data: undefined, isLoading: false }
  }, [currency, state, trade])
}