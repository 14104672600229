import { Trans } from "@lingui/macro";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { StyledConnectButton, Web3StatusConnectWrapper } from "components/Web3Status";
import { Link } from "react-router-dom";
import { isVerified as isWalletVerified} from '@haven1network/h1-fee'
import { useEffect, useState } from 'react'
import { ProofOfIdentityContract } from '@haven1/blockchain-sdk/contract';
import { JsonRpcProvider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';

export function isVerified(address: string | undefined): boolean {

    const [result, setResult] = useState(false)
    useEffect(() => {
        if(address !== undefined){
            async function fetchWallet() {
                setResult(await isWalletVerified(
                    address!,
                    process.env.REACT_APP_ACCOUNT_MANAGER_ADDRESS! as string,
                    process.env.REACT_APP_RPC_URL! as string,
                    Number.parseInt(process.env.REACT_APP_CHAIN_ID!),
                    process.env.REACT_APP_CHAIN_NAME! as string
                ));
            }
    
            fetchWallet();
        }
    }, [address])
    return result;
}

export function isSuspended(address: string | undefined): { isSuspended: boolean, isLoading: boolean } {
    const [isSuspended, setIsSuspended] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const contract = ProofOfIdentityContract.connect(
        process.env.REACT_APP_PROOF_OF_ID_CONTRACT_ADDRESS! as string,
        new JsonRpcProvider(process.env.REACT_APP_RPC_URL as string)
    );

    useEffect(() => {
        if (!address) return;

        contract.isSuspended(address).then((suspended) => {
            setIsSuspended(suspended);
        }).catch((error) => {
            console.error('Error fetching POI status:', error)
        }).finally(() => {
            setIsLoading(false);
        })
    }, [address])

    return { isSuspended, isLoading };
}

export const verifyLink = process.env.REACT_APP_VERIFY_LINK! as string

export function VerifyAccountButtonLight() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = isSuspended(account);
    return (
        <Link
            to={(suspended || isSuspendedLoading) ? "" : verifyLink}
            target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
            style={{
                textDecoration: "none",
                pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
            }}
        >
            <ButtonLight onClick={() => {}} fontWeight={535} $borderRadius="16px" disabled={suspended || isSuspendedLoading}>
                <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
            </ButtonLight>
        </Link>
    );
}

export function VerifyAccountButtonPrimary() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = isSuspended(account);
    return (
        <Link
            to={(suspended || isSuspendedLoading) ? "" : verifyLink}
            target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
            style={{
                textDecoration: "none",
                pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
            }}
        > 
            <ButtonPrimary
                style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
                onClick={() => {}}
                disabled={suspended || isSuspendedLoading}
            >
                <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
            </ ButtonPrimary>
        </Link>
    );
}

export function VerifyAccountButton() {
    const { account } = useWeb3React()
    const { isSuspended: suspended, isLoading: isSuspendedLoading } = isSuspended(account);
    return (
        <Web3StatusConnectWrapper
            tabIndex={0}
            onKeyPress={(e) => e.key === 'Enter'}
            onClick={() => {}}
         >
            <Link
                to={(suspended || isSuspendedLoading) ? "" : verifyLink}
                target={(suspended || isSuspendedLoading) ? "_blank" : "self"}
                style={{
                    textDecoration: "none",
                    pointerEvents: suspended || isSuspendedLoading ? "none" : "auto",
                    cursor: suspended || isSuspendedLoading ? "not-allowed" : "pointer"
                }}
            > 
                <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet" style={{padding: "20px"}} disabled={suspended || isSuspendedLoading}>
                    <Trans>{isSuspendedLoading ? "Loading..." : suspended ? "Account Suspended" : "Verify account"}</Trans>
                </StyledConnectButton>
            </Link>
        </Web3StatusConnectWrapper>
    );
}